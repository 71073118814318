@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap);
* {
    margin: 0;
    padding: 0;
}

body,html{
    width: 100vw;
    height: 100vh;
}

tbody{
    display: block;
}

::-webkit-scrollbar{
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: #292F36;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
